import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.timers.js";
import Vue from 'vue';
export default Vue.extend({
  name: 'ShowMore',
  props: {
    text: String
  },
  data: function data() {
    return {
      refId: "smt".concat(Math.random().toString()),
      showAll: false,
      // Watch out: this number must be equal to the css max-height below
      maxHeight: 54,
      actualHeight: 0
    };
  },
  watch: {
    text: function text() {
      var _this = this;
      // Reinstate the show more link when the text changes
      this.showAll = false;

      // Schedule the callback to be run asynchronously
      // by then, the text has rendered
      setTimeout(function () {
        var el = _this.$refs[_this.refId];
        _this.actualHeight = _this.refIsElement(el) ? el.clientHeight : 0;
      }, 10);
    }
  },
  mounted: function mounted() {
    var el = this.$refs[this.refId];
    this.actualHeight = this.refIsElement(el) ? el.clientHeight : 0;
  },
  methods: {
    refIsElement: function refIsElement(ref) {
      return !!(ref && 'clientHeight' in ref);
    }
  }
});